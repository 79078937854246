import { Grid } from "@chakra-ui/react";
import CreditInfo from "./components/overview/CreditInfo";
import PersonalInfo from "./components/overview/PersonalInfo";


const Overview = () => {
  return (
    <Grid templateColumns="repeat(1fr)" gap={6} w="45%">
        <PersonalInfo />
        <CreditInfo />
    </Grid>
  );
};

export default Overview;
