// NavBarLinksDashboard.js
// Chakra Imports
import {
  Avatar,
  Tooltip,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
  useColorModeValue,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button
} from '@chakra-ui/react';
// Icons
import { SunIcon, MoonIcon, DownloadIcon } from '@chakra-ui/icons';
// AuthContext
import { useAuth } from 'AuthContext';
// PDF Exporter Library
import jsPDF from 'jspdf';
import { toPng } from 'html-to-image';
import { useDateRange } from 'contexts/DateRangeContext';
import { format } from 'date-fns';
import { useLocation } from 'contexts/LocationContext';
import UsageDisplay from './CreditsUsage';
import { capitalizeWords } from 'utils/dataFormatter';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function HeaderLinks(props) {
  const { secondary } = props;
  const { email, firstName, lastName, logout, brandText } = useAuth();
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();

  let menuBg = useColorModeValue('white', 'navy.800');

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const { reportTemplateRef } = useAuth();

  const exportAsPDF = async () => {
    await new Promise(resolve => setTimeout(resolve, 1000));

    reportTemplateRef.current.style.backgroundColor = '#080E2E';

    // Scroll to top before capturing the entire content
    window.scrollTo(0, 0);

    // Capture the content as an image
    const dataUrl = await toPng(reportTemplateRef.current, {
      bgcolor: '#ffffff',  // Background color (optional)
      quality: 1.0,        // Full quality image
    });

    const pdf = new jsPDF('p', 'mm', 'a4');
    const padding = 10;  // Define padding in mm
    const imgWidth = 210 - 2 * padding;  // A4 width minus padding
    const pageHeight = 295 - 2 * padding;  // A4 height minus padding
    const imgHeight = (reportTemplateRef.current.scrollHeight * imgWidth) / reportTemplateRef.current.scrollWidth;  // Maintain aspect ratio

    // Add text before the image
    pdf.setFontSize(16);  // Set font size
    pdf.text(brandText, padding, 20);  // Add a title
    pdf.setFontSize(12);  // Set a smaller font size for additional text
    pdf.text(`${location} (${format(dateRange[0], 'MMMM d, yyyy')} - ${format(dateRange[1], 'MMMM d, yyyy')})`, padding, 30);  // Add a date

    // Add the image below the text
    let position = 40;  // Start position after the text
    pdf.addImage(dataUrl, 'PNG', padding, position, imgWidth, imgHeight);
    let heightLeft = imgHeight - (pageHeight - position);

    // If the image height exceeds one page, create more pages
    while (heightLeft > 0) {
      position = heightLeft - imgHeight + padding;
      pdf.addPage();
      pdf.addImage(dataUrl, 'PNG', padding, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // Save the PDF
    pdf.save('download.pdf');
    reportTemplateRef.current.style.backgroundColor = 'unset';
  };

  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();

  const onClose = () => setIsOpen(false);

  const handleLogoutClick = () => {
    setIsOpen(true);
  };

  const confirmLogout = () => {
    logout();
    onClose();
  };

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      justifyContent="space-around"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="999px"
      boxShadow={shadow}
      mt={{ base: '10px', md: '0' }}
    >      
      <UsageDisplay />
      <Tooltip label="Export page as PDF" aria-label="Export page as PDF tooltip">
        <IconButton
          aria-label="Export page as PDF"
          icon={<DownloadIcon />}
          onClick={exportAsPDF}
          variant="ghost"
        />
      </Tooltip>
      <Tooltip label={`Enable ${colorMode === 'dark' ? 'light' : 'dark'} mode`} aria-label="Download as PDF tooltip">
        <IconButton
          aria-label="Toggle theme"
          icon={colorMode === 'dark' ? <MoonIcon /> : <SunIcon />}
          onClick={toggleColorMode}
          variant="ghost"
          mr={2}
        />
      </Tooltip>
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color="white"
            name={`${firstName} ${lastName}`}
            bg="#5158FF"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px" direction="column">
            <Text
              ps="20px"
              pt="16px"
              w="100%"
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              {capitalizeWords(`${firstName} ${lastName}`)}
            </Text>
            <Text
              ps="20px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="xs"
              color={textColor}
            >
              {email}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              _hover={{ transform: 'scale(1.05)', boxShadow: 'md' }}
              _focus={{ bg: 'none' }}
              color={textColor}
              borderRadius="8px"
              px="14px"
              transition="all 0.2s ease-in-out"
            >
              <Text onClick={() => navigate('/profile')} _hover={{ cursor: 'pointer' }} fontSize="sm">Manage account</Text>
            </MenuItem>
            <MenuItem
              _hover={{ transform: 'scale(1.05)', boxShadow: 'md' }}
              _focus={{ bg: 'none' }}
              color="red.400"
              borderRadius="8px"
              px="14px"
              transition="all 0.2s ease-in-out"
            >
              <Text fontSize="sm" onClick={handleLogoutClick}>Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Logout
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to log out?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="teal" onClick={confirmLogout} ml={3}>
                Log out
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}
