import { Avatar, Box, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { capitalizeWords } from "utils/dataFormatter";
import backgroundImageProfile from "../../../../assets/img/background-image-profile.jpg";
import { useAuth } from "AuthContext";

const PersonalInfo = () => {
  const { firstName, lastName, userType, jobTitle } = useAuth();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Box
      bg={useColorModeValue('white', 'navy.800')}
      borderRadius="20px"
      boxShadow={shadow}
      p={8}
      pt="110px"
      minHeight="350px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      w="full"
      position="relative"
      overflow="hidden"
    >
      {/* Background image */}
      <Box
        position="absolute"
        top={5}
        left={5}
        right={5}
        height="40%"  // The background image covers the top half
        backgroundImage={`url(${backgroundImageProfile})`}
        backgroundSize="cover"
        backgroundPosition="center"
        borderRadius={15}
      />
      
      {/* Content */}
      <VStack spacing={2} align="center" position="relative" zIndex={1}>
        <Avatar
          name={`${firstName} ${lastName}`}
          color="white"
          bg="#5158FF"
          size="xl"
          mb={4}
          zIndex={2} // Ensure avatar appears above the background image
        />
        <Text fontSize="lg" fontWeight="bold" color={textColor}>
          {capitalizeWords(`${firstName} ${lastName}`)}
        </Text>
        <Text fontSize="sm" color={textColor}>
          {jobTitle || '-'}
        </Text>
        <Text fontSize="sm" color={textColor}>
          User Type: {userType === "MasterUser" ? "Admin" : userType === "AdminUser" ? "Creator" : "Viewer"}
        </Text>
      </VStack>
    </Box>
  );
};

export default PersonalInfo;
