import {
  Button,
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";
import { useAuth } from "AuthContext";
import { useState } from "react";

const DeleteAccount = () => {
  const [loading, setLoading] = useState(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { selfDelete } = useAuth();

  const handleDelete = async () => {
    try {
      setLoading(true);
      await selfDelete();
      toast({
        title: "Success",
        description: "Your account has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top"
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to delete your account.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top"
      });
    } finally {
      setLoading(false);
    }
    onClose();
  };

  return (
    <Flex
      bg={useColorModeValue('white', 'navy.800')}
      borderRadius="20px"
      boxShadow={shadow}
      p={8}
      display="flex"
      alignItems="center"
      w="full"
      direction="row"
      justifyContent="space-between"
    >
      <Flex direction="column" w="full">
        <Text fontSize="xl" fontWeight="bold" color={textColor}>Delete this account</Text>
        <Text fontSize="sm" color={textColor}>Permanently delete this account</Text>
      </Flex>
      <Button
        size="sm"
        fontSize="sm"
        onClick={onOpen}
        py={5}
        px={7}
        color="rgb(221, 102, 87)"
        bgColor="transparent"
        border="1px solid rgb(221, 102, 87)"
      >
        Delete Account
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Account Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this account? This action cannot be undone.</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose} disabled={loading}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={handleDelete}
              isLoading={loading}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default DeleteAccount;
