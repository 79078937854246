import { Flex, Text, Divider, useColorModeValue } from "@chakra-ui/react";

const ProgressBar = ({ steps, currentStep }) => {
  const activeColor = useColorModeValue("blue.500", "blue.300");
  const inactiveColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Flex justify="space-between" align="center" width="full" position="relative" mb={6}>
      {steps.map((step, index) => {
        const isActive = index <= currentStep;

        return (
          <Flex key={index} align="center" position="relative">
            {/* Circle */}
            <Flex
              justify="center"
              align="center"
              width="13px"
              height="13px"
              borderRadius="full"
              border={isActive ? "white 2px solid" : "none"}
              bg={isActive ? activeColor : inactiveColor}
              color="white"
              fontSize="sm"
              fontWeight="bold"
            />

            {/* Step Label */}
            <Text
              position="absolute"
              top="22px"
              fontSize="xs"
              color="white"
              textAlign="center"
              transform="translateX(-40%)"
              width="max-content"
              whiteSpace="nowrap"
            >
              {step}
            </Text>

            {/* Line */}
            {index < steps.length - 1 && (
              <Divider
                orientation="horizontal"
                borderColor={index < currentStep && isActive ? activeColor : inactiveColor}
                borderWidth="2px"
                width="150px"
                ml="5px"
                mr="5px"
              />
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default ProgressBar;
