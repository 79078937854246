import { Button, Flex, FormControl, FormLabel, Grid, Input, Text, useColorModeValue } from "@chakra-ui/react";

const UserInfo = ({ handleInputChange, formData, setIsAddingNewUser, setCurrentStep }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const getNextButtonDisabled = () => {
    return !formData.firstName || !formData.lastName || !formData.email || !isEmailValid(formData.email);
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <Flex
      bg={useColorModeValue('white', 'navy.800')}
      borderRadius="20px"
      boxShadow={shadow}
      p={8}
      display="flex"
      w="60%"
      direction="column"
    >
      <Text fontSize="xl" fontWeight="bold" color={textColor}>User Info</Text>
      <Flex direction="column" w="full" mt={5}>
        <Grid templateColumns="repeat(2, 1fr)" mb={4} gap={3}>
          <FormControl>
            <FormLabel color={textColor} fontSize="sm">First Name *</FormLabel>
            <Input
              name="firstName"
              onChange={handleInputChange}
              color={textColor}
              fontSize="sm"
              value={formData?.firstName}
              placeholder="eg. John"
            />
          </FormControl>
          <FormControl>
            <FormLabel color={textColor} fontSize="sm">Last Name *</FormLabel>
            <Input
              name="lastName"
              onChange={handleInputChange}
              color={textColor}
              fontSize="sm"
              value={formData?.lastName}
              placeholder="eg. Doe"
            />
          </FormControl>
        </Grid>
        <Grid templateColumns="repeat(2, 1fr)" mb={4}>
          <FormControl>
            <FormLabel color={textColor} fontSize="sm">Email Address *</FormLabel>
            <Input
              name="email"
              onChange={handleInputChange}
              color={textColor}
              fontSize="sm"
              value={formData?.email}
              placeholder="eg. john.doe@gmail.com"
            />
          </FormControl>
        </Grid>
        <Flex justifyContent="center" gap={3}>
          <Button
            size="sm"
            bg="transparent"
            border="white 1px solid"
            w="25%"
            py={5}
            mt={3}
            onClick={() => setIsAddingNewUser(false)}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            bg="#5158FF"
            w="25%"
            py={5}
            mt={3}
            onClick={() => setCurrentStep(1)}
            disabled={getNextButtonDisabled()}
          >
            Next
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default UserInfo;
