import { Box, Flex, Icon, Text, VStack } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { useAuth } from "AuthContext";
import { BsLightningChargeFill } from "react-icons/bs";

const CreditInfo = () => {
  const { sparkCredits } = useAuth();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Box
      bg={useColorModeValue('white', 'navy.800')}
      borderRadius="20px"
      boxShadow={shadow}
      p={8}
      minHeight="350px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      w="full"
      overflow="hidden"
    >
      <Flex direction="column" justifyContent="center" alignItems="center">
        <Box
          bg="rgb(29, 37, 73)"
          borderRadius="full"
          p={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxShadow="md"
        >
          <Box
            border="1px solid"
            borderRadius="full"
            borderColor="white"
            p={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
            boxShadow="md"
          >
            <Icon
              as={BsLightningChargeFill}
              w="30px"
              h="30px"
              color="white"
            />
          </Box>
        </Box>

        {/* Content in VStack */}
        <VStack spacing={2} align="center" position="relative" mt={7}>
          <Text fontSize="lg" fontWeight="bold" color={textColor}>
            Lightning Credits
          </Text>
          <Text fontSize="sm" color={textColor}>
            You have {sparkCredits} Lightning Credits left!
          </Text>
        </VStack>
      </Flex>
    </Box>
  );
};

export default CreditInfo;
