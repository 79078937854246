// AuthUtils.js
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import QRCode from "qrcode";
import AWS from "aws-sdk";

const allAvailablePages = [
    { title: "Overview", value: true },
    {
        title: "AI Insights",
        value: true,
        children: [
            { title: "AI Summaries", value: true },
            { title: "AI Chat", value: true },
        ],
        },
    {
        title: "Marketing",
        value: true,
        children: [
            { title: "Marketing Insights", value: true },
            { title: "Meta", value: true },
            { title: "Google", value: true },
        ],
    },
    {
        title: "Tickets",
        value: true,
        children: [
            { title: "Ticket Insights", value: true },
            { title: "Attendance", value: true },
            { title: "Heatmap", value: true },
        ],
    },
    { title: "Custom Reports", value: true },
];

// Fetch the user pool data based on email
const getUserPoolData = async (email) => {
    try {
        const response = await fetch(`https://9ydxqdp5kl.execute-api.us-east-2.amazonaws.com/dev/user-client?email=${email}`);
        const data = await response.json();
        if (data.UserPoolId && data.ClientId) {
            const { UserPoolId, ClientId } = data;
            return { UserPoolId, ClientId };
        } else {
            console.error("Client not found for the provided email.");
            console.error(`Error: ${JSON.stringify(data)}`);
            return null;
        }
    } catch (error) {
        console.error("Error fetching or processing user pool data:", error);
        return null;
    }
};

const getUsersAPI = (company) => {
    const mapping = {
        "illuminarium": process.env.REACT_APP_ILLUMINARIUM_USERS_URL,
        "sloomoo_institute": process.env.REACT_APP_SLOOMOO_USERS_URL,
        "a_bunch_of_stuff": process.env.REACT_APP_A_BUNCH_OF_STUFF_USERS_URL,
        "arcadia_earth": process.env.REACT_APP_ARCADIA_USERS_URL,
        "beat_the_bomb": process.env.REACT_APP_BEAT_THE_BOMB_USERS_URL,
        "epic_waters": process.env.REACT_APP_EPIC_WATERS_USERS_URL
    };

    return mapping[company];
};

const signIn = async (email, password, setIsMfaFormOpen, setOnMfaSubmit, setIsNewPasswordFormOpen, setOnNewPasswordSubmit, setField, setIsMfaEnabled) => {
    const userPoolData = await getUserPoolData(email);
    if (!userPoolData) return Promise.reject(new Error("Login error. Please try again."));

    const userPool = new CognitoUserPool({
        UserPoolId: userPoolData.UserPoolId,
        ClientId: userPoolData.ClientId,
    });

    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: email,
            Pool: userPool,
        });

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });

        user.authenticateUser(authDetails, {
            onSuccess: (result) => {
                const accessToken = result.getAccessToken().getJwtToken();
                const refreshToken = result.getRefreshToken().getToken();

                user.getUserAttributes((err, attributes) => {
                    if (err) {
                      console.error('Error retrieving user attributes:', err);
                      reject(err);
                    } else {
                      const userAttributes = {};
                      attributes.forEach((attribute) => {
                        userAttributes[attribute.getName()] = attribute.getValue();
                      });
                      resolve({ accessToken, refreshToken, user, userAttributes, userPoolId: userPoolData.UserPoolId, clientId: userPoolData.ClientId });
                    }
                });
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                setIsNewPasswordFormOpen(true);
                setOnNewPasswordSubmit(() => {
                    return (newPassword) => {
                        if (!newPassword) return;
                        user.completeNewPasswordChallenge(newPassword, {}, {
                            onSuccess: (result) => {
                              const accessToken = result.getAccessToken().getJwtToken();
                              const refreshToken = result.getRefreshToken().getToken();
          
                              user.getUserAttributes((err, attributes) => {
                                  if (err) {
                                      console.error('Error retrieving user attributes:', err);
                                      reject(err);
                                  } else {
                                      const userAttributes = {};
                                      attributes.forEach((attribute) => {
                                          userAttributes[attribute.getName()] = attribute.getValue();
                                      });
                                      resolve({ accessToken, refreshToken, user, userAttributes, userPoolId: userPoolData.UserPoolId, clientId: userPoolData.ClientId });
                                  }
                              });
                            },
                            onFailure: (err) => {
                              console.error('Error changing password:', err);
                              reject(err);
                            },
                          });
                    }
                });
            },
            totpRequired: (challengeName, challengeParameters) => {
                setIsMfaFormOpen(true);
                setOnMfaSubmit(() => {
                    return (mfaCode) => {
                        if (!mfaCode) return;
                        user.sendMFACode(
                            mfaCode,
                            {
                                onSuccess: (result) => {
                                    setIsMfaFormOpen(false);
                                    const accessToken = result.getAccessToken().getJwtToken();
                                    const refreshToken = result.getRefreshToken().getToken();
        
                                    user.getUserAttributes((err, attributes) => {
                                        if (err) {
                                            console.error("Error retrieving user attributes:", err);
                                            reject(err);
                                        } else {
                                            const userAttributes = {};
                                            attributes.forEach((attribute) => {
                                                userAttributes[attribute.getName()] = attribute.getValue();
                                            });
                                            setIsMfaEnabled(true);
                                            setField("isMfaEnabled", "true");
                                            resolve({
                                                accessToken,
                                                refreshToken,
                                                user,
                                                userAttributes,
                                                userPoolId: userPoolData.UserPoolId,
                                                clientId: userPoolData.ClientId,
                                            });
                                        }
                                    });
                                },
                                onFailure: (err) => {
                                    setIsMfaFormOpen(false);
                                    console.error("MFA authentication failed:", err);
                                    reject(err);
                                },
                            },
                            challengeName
                        );
                    }
                });
            },
            onFailure: (err) => {
                console.error('Login error: ', err);
                reject(err);
            },
        });
    });
};

const refreshToken = async () => {
    const email = sessionStorage.getItem('email') || localStorage.getItem('email');
    
    if (!email) {
        return Promise.reject(new Error('No email found'));
    }

    const userPoolData = await getUserPoolData(email);
    const userPool = new CognitoUserPool({
        UserPoolId: userPoolData.UserPoolId,
        ClientId: userPoolData.ClientId,
    });

    return new Promise((resolve, reject) => {
        const refreshToken = sessionStorage.getItem('refreshToken') || localStorage.getItem('refreshToken')
    
        if (!refreshToken) {
            return reject(new Error('No refresh token found'));
        }
    
        const cognitoRefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });
    
        const user = userPool.getCurrentUser();
    
        if (!user) {
            return reject(new Error('No user found'));
        }
    
        user.refreshSession(cognitoRefreshToken, (err, session) => {
            if (err) {
                return reject(err);
            }
    
            const accessToken = session.getAccessToken().getJwtToken();

            user.getUserAttributes((err, attributes) => {
                if (err) {
                  console.error('Error retrieving user attributes:', err);
                  reject(err);
                } else {
                  const userAttributes = {};
                  attributes.forEach((attribute) => {
                    userAttributes[attribute.getName()] = attribute.getValue();
                  });
                  resolve({ accessToken, refreshToken, user, userAttributes, userPoolId: userPoolData.UserPoolId, clientId: userPoolData.ClientId });
                }
            });
        });
    });
};

const initiatePasswordReset = async (email) => {
    const userPoolData = await getUserPoolData(email);

    const userPool = new CognitoUserPool({
        UserPoolId: userPoolData.UserPoolId,
        ClientId: userPoolData.ClientId,
    });

    return new Promise((resolve, reject) => {
        const userData = {
            Username: email,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.forgotPassword({
            onSuccess: (result) => {
                console.log('Password reset successfully:', result);
                resolve(result);
            },
            onFailure: (err) => {
                console.error('Error initiating password reset:', err);
                reject(err);
            },
            inputVerificationCode: () => {
                console.log('Password reset code sent successfully');
                resolve();
            }
        });
    });
};

const confirmPasswordReset = async (email, verificationCode, newPassword) => {
    const userPoolData = await getUserPoolData(email);

    const userPool = new CognitoUserPool({
        UserPoolId: userPoolData.UserPoolId,
        ClientId: userPoolData.ClientId,
    });

    return new Promise((resolve, reject) => {
        const userData = {
            Username: email,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onSuccess: () => {
                console.log('Password reset successful');
                resolve();
            },
            onFailure: (err) => {
                console.error('Error confirming password reset:', err);
                reject(err);
            },
        });
    });
};

const handleMfaSetup = async (email) => {
    const userPoolData = await getUserPoolData(email);

    const userPool = new CognitoUserPool({
        UserPoolId: userPoolData.UserPoolId,
        ClientId: userPoolData.ClientId,
    });

    const currentUser = userPool.getCurrentUser();

    return new Promise((resolve, reject) => {
        currentUser.getSession((err, session) => {
            if (err) {
                console.error("Error getting session:", err);
                return reject(err);
            }

            // Request MFA setup (TOTP)
            currentUser.associateSoftwareToken({
                associateSecretCode: (response) => {
                    const associateSecretCode = response;
                    const otpauthURL = `otpauth://totp/Xeuscloud:${encodeURIComponent(
                        email
                    )}?secret=${associateSecretCode}&issuer=Xeuscloud`;

                    QRCode.toDataURL(otpauthURL, (err, qrCodeDataURL) => {
                        if (err) {
                          console.error("Error generating QR Code:", err);
                          return reject(err);
                        }
                        resolve({ qrCodeUrl: qrCodeDataURL, associateSecretCode, isSetupInProgress: true, mfaStatus: "Enabled" });
                      });

                },
                onFailure: (err) => {
                    console.error("Error associating software token:", err);
                    reject(err);
                },
            });
        });
    });
};
  
const handleMfaVerify = async (email, otp) => {
    const userPoolData = await getUserPoolData(email);

    const userPool = new CognitoUserPool({
        UserPoolId: userPoolData.UserPoolId,
        ClientId: userPoolData.ClientId,
    });

    const currentUser = userPool.getCurrentUser();

    return new Promise((resolve, reject) => {
        currentUser.getSession((err, session) => {
        if (err) {
            console.error("Error getting session:", err);
            return reject(err);
        }

        console.log("Session:", session);

        // Check if the session is valid
        if (!session.isValid()) {
            console.log("Session expired. Refreshing session...");
            currentUser.refreshSession(session.getRefreshToken(), (err, newSession) => {
                if (err) {
                    console.error("Error refreshing session:", err);
                    return reject(err);
                }

                console.log("Session refreshed successfully.");
                
                // Proceed with MFA verification after refreshing the session
                currentUser.verifySoftwareToken(otp, "MFA-Setup", {
                    onSuccess: (response) => {
                        resolve({ isSetupInProgress: false });
                    },
                    onFailure: (err) => {
                        console.error("Error verifying software token:", err);
                        reject(err);
                    },
                });
            });
        } else {
            // Proceed with MFA verification if session is still valid
            currentUser.verifySoftwareToken(otp, "MFA-Setup", {
                onSuccess: (response) => {
                    resolve({ isSetupInProgress: false });
                },
                onFailure: (err) => {
                    console.error("Error verifying software token:", err);
                    reject(err);
                },
            });
        }
        });
    });
};

const enableTotpForUser = async (email, userPoolId) => {
    console.log("Enabling MFA (TOTP) for user...");

    AWS.config.update({
        region: process.env.REACT_APP_AWS_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    });

    const cognito = new AWS.CognitoIdentityServiceProvider();
  
    try {
      const params = {
        UserPoolId: userPoolId,
        Username: email,
        SoftwareTokenMfaSettings: {
          Enabled: true,
          PreferredMfa: true,
        },
      };
  
      const result = await cognito.adminSetUserMFAPreference(params).promise();
  
      console.log("MFA (TOTP) enabled successfully for user:", result);
    } catch (err) {
      console.error("Error enabling MFA (TOTP) for user:", err);
      throw (err);
    }
};

const disableTotpForUser = async (email, userPoolId) => {
    console.log("Disabling MFA (TOTP) for user...");

    AWS.config.update({
        region: process.env.REACT_APP_AWS_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    });
  
    const cognito = new AWS.CognitoIdentityServiceProvider();
  
    try {
      const params = {
        UserPoolId: userPoolId,
        Username: email,
        SoftwareTokenMfaSettings: {
          Enabled: false,
          PreferredMfa: false,
        },
      };
  
      const result = await cognito.adminSetUserMFAPreference(params).promise();
  
      console.log("MFA (TOTP) disabled successfully for user:", result);
    } catch (err) {
      console.error("Error disabling MFA (TOTP) for user:", err);
      throw (err);
    }
};

export {
    signIn,
    refreshToken,
    initiatePasswordReset,
    confirmPasswordReset,
    handleMfaSetup,
    handleMfaVerify,
    enableTotpForUser,
    disableTotpForUser,
    getUsersAPI,
    allAvailablePages
}
