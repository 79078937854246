import {
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { useAuth } from "AuthContext";
import AWS from 'aws-sdk';
import { useCallback, useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import UserDetails from "./components/UserDetails";
import DeleteUserModal from "./components/users/DeleteUserModal";
import NewUserForm from "./components/users/NewUserForm";
import UserTable from "./components/users/UserTable";
import EditUserForm from "./components/users/EditUserForm";
import { getUsersAPI } from "utils/AuthUtils";
import { allAvailablePages } from "utils/AuthUtils";
import locationsList from "variables/locationsList";

const getUserAttribute = (user, attributeName) => {
  return user.Attributes.find(attr => attr.Name === attributeName)?.Value || '';
};

const transformUserData = (users, company) => {
  return users.map(user => ({
    id: user.Username,
    name: `${getUserAttribute(user, 'given_name')} ${getUserAttribute(user, 'family_name')}`,
    firstName: getUserAttribute(user, 'given_name'),
    lastName: getUserAttribute(user, 'family_name'),
    email: getUserAttribute(user, 'email'),
    company: getUserAttribute(user, 'custom:Client'),
    credits: getUserAttribute(user, 'custom:Credits'),
    userType: getUserAttribute(user, 'custom:UserType'),
    pages: getUserAttribute(user, 'custom:Pages') === 'All' ? allAvailablePages : JSON.parse(getUserAttribute(user, 'custom:Pages')),
    location: getUserAttribute(user, 'custom:Location') === 'All' ? locationsList[company] : JSON.parse(getUserAttribute(user, 'custom:Location')),
    startDate: user.UserCreateDate,
    status: user.UserStatus,
  }));
};

const Users = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [isAddingNewUser, setIsAddingNewUser] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const { isOpen: isDeleteUserModalOpen, onOpen: onDeleteUserModalOpen, onClose: onDeleteUserModalClose } = useDisclosure();
  const toast = useToast();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const refreshToken = localStorage.getItem('refreshToken') || sessionStorage.getItem('refreshToken') || null;
  const { userPoolId, clientId, userType, company } = useAuth();
  
  AWS.config.update({ region: process.env.REACT_APP_AWS_REGION });

  const fetchUsers = useCallback(async () => {
    try {
      setLoading(true);
      const cognito = new AWS.CognitoIdentityServiceProvider();
  
      const params = {
        AuthFlow: 'REFRESH_TOKEN_AUTH',
        ClientId: clientId,
        AuthParameters: {
          REFRESH_TOKEN: refreshToken,
        },
      };
  
      const data = await cognito.initiateAuth(params).promise();
      const idToken = data.AuthenticationResult.IdToken;
  
      const response = await fetch(
        `${getUsersAPI(company)}?userPoolId=${userPoolId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch users: ${errorText}`);
      }
  
      const { users } = await response.json();
      const transformedUsers = transformUserData(users, company);
      setUsers(transformedUsers);
    } catch (error) {
      console.error('Error fetching users: ', error);
      toast({
        title: 'Something went wrong',
        description: 'Please try again',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setLoading(false);
    }
  }, [clientId, refreshToken, userPoolId, toast, setLoading, setUsers]);

  useEffect(() => {  
    fetchUsers();
  }, [fetchUsers]);

  // const handleUserClick = (user) => {
  //   setSelectedUser(selectedUser?.id === user.id ? null : user);
  // };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true);
      const cognito = new AWS.CognitoIdentityServiceProvider();
  
      const params = {
        AuthFlow: 'REFRESH_TOKEN_AUTH',
        ClientId: clientId,
        AuthParameters: {
          REFRESH_TOKEN: refreshToken,
        },
      };
  
      const data = await cognito.initiateAuth(params).promise();
      const idToken = data.AuthenticationResult.IdToken;
  
      const response = await fetch(
        `${getUsersAPI(company)}?userPoolId=${userPoolId}&id=${userToDelete.id}&targetUserType=${userToDelete.userType || "ViewerType"}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to delete user: ${errorText}`);
      }
  
      setUsers(users.filter(user => user.id !== userToDelete.id));
      setUserToDelete(null);
      onDeleteUserModalClose();
      toast({
        title: "User deleted.",
        description: `${userToDelete.email} has been removed.`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      console.error('Error deleting the user:', error);
      toast({
        title: 'Something went wrong',
        description: 'Please try again',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setLoading(false);
    }
  };  
  
  if (isAddingNewUser) {
    return (<NewUserForm setIsAddingNewUser={setIsAddingNewUser} loading={loading} setLoading={setLoading} fetchUsers={fetchUsers} />);
  }

  if (userToEdit) {
    return (<EditUserForm userToEdit={userToEdit} setUserToEdit={setUserToEdit} loading={loading} setLoading={setLoading} fetchUsers={fetchUsers} />);
  }

  return (
    <Flex w="100%">
      <Flex
        direction="column"
        flex="1"
        borderColor="gray.600"
        pr="5"
        overflowY="auto"
        maxHeight="100vh"
      >
        <Flex direction="row" gap={5} justifyContent="space-between" alignItems="center">
          <InputGroup mb={4} mt={1} h="40px" w="35%">
            <InputLeftElement>
              <Icon as={MdSearch} color="gray.400" />
            </InputLeftElement>
            <Input
              placeholder="Search users..."
              size="sm"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              variant="outline"
              borderColor="gray.600"
              _focus={{
                borderColor: "#5158FF",
                boxShadow: "0 0 0 1px #5158FF",
              }}
              _active={{
                borderColor: "#5158FF",
              }}
              borderRadius="md"
              px={3}
              py={2}
              color={textColor}
              h="100%"
            />
          </InputGroup>
          {
            userType !== 'ViewerUser' &&
              <Button
                borderRadius="10px"
                px={10}
                fontSize="xs"
                bg="#5158FF"
                onClick={() => setIsAddingNewUser(true)}
              >
                Add New User
              </Button>
          }
        </Flex>

        {loading ? (
          <Flex justify="center" align="center" h="100vh">
            <Spinner size="lg" />
          </Flex>
        ) : <UserTable users={users} searchTerm={searchTerm} setUserToEdit={setUserToEdit} setUserToDelete={setUserToDelete} onDeleteUserModalOpen={onDeleteUserModalOpen} />}
      </Flex>

      {selectedUser && <UserDetails selectedUser={selectedUser} setSelectedUser={setSelectedUser} />}

      <DeleteUserModal isOpen={isDeleteUserModalOpen} onClose={onDeleteUserModalClose} onDelete={handleDeleteConfirm} loading={loading} />
    </Flex>
  );
};

export default Users;
