import { 
  Button, 
  Flex, 
  FormControl, 
  FormLabel, 
  Input, 
  Text, 
  useColorModeValue, 
  useToast, 
  InputGroup, 
  InputRightElement, 
  IconButton 
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useAuth } from "AuthContext";
import { useState } from "react";

const ChangePassword = () => {
  const initialFormData = {
    oldPassword: '',
    newPassword: '',
    passwordConfirmation: '',
  };
  const [formData, setFormData] = useState(initialFormData);
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    passwordConfirmation: false,
  });
  const [loading, setLoading] = useState(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { changePassword } = useAuth();
  const toast = useToast();
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const toggleShowPassword = (field) => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
  
    try {
      setLoading(true);
      await changePassword(formData);

      toast({
        title: "Success",
        description: "Your password has been changed successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top"
      });
  
      setFormData(initialFormData);
    } catch (error) {
      console.error("Error during password change:", error);

      toast({
        title: "Error",
        description: error.message || "An error occurred while changing your password.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top"
      });
    } finally {
      setLoading(false);
    }
  };  

  const validateForm = () => {
    const { oldPassword, newPassword, passwordConfirmation } = formData;
    if (!oldPassword || !newPassword || !passwordConfirmation) {
      toast({
        title: "Form Incomplete",
        description: "Please fill out all required fields.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }
    if (newPassword !== passwordConfirmation) {
      toast({
        title: "Error changing password",
        description: "New Password and New Password Confirmation must be the same.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  return (
    <Flex
      bg={useColorModeValue('white', 'navy.800')}
      borderRadius="20px"
      boxShadow={shadow}
      p={8}
      display="flex"
      alignItems="center"
      w="full"
      direction="column"
    >
      <Flex direction="column" w="full" mb={8}>
        <Text fontSize="xl" fontWeight="bold" color={textColor}>Change password</Text>
        <Text fontSize="sm" color={textColor}>Set your new password</Text>
      </Flex>

      <Flex direction="column" w="full">
        <FormControl>
          <FormLabel color={textColor} fontSize="sm">Old Password *</FormLabel>
          <InputGroup>
            <Input
              name="oldPassword"
              onChange={handleInputChange}
              color={textColor}
              fontSize="sm"
              placeholder="Old Password"
              value={formData.oldPassword}
              type={showPassword.oldPassword ? "text" : "password"}
            />
            <InputRightElement>
              <IconButton
                aria-label="Toggle old password visibility"
                icon={showPassword.oldPassword ? <RiEyeCloseLine /> : <MdOutlineRemoveRedEye />}
                onClick={() => toggleShowPassword("oldPassword")}
                size="sm"
                variant="ghost"
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl>
          <FormLabel color={textColor} fontSize="sm" mt={4}>New Password *</FormLabel>
          <InputGroup>
            <Input
              name="newPassword"
              onChange={handleInputChange}
              color={textColor}
              fontSize="sm"
              placeholder="New Password"
              value={formData.newPassword}
              type={showPassword.newPassword ? "text" : "password"}
            />
            <InputRightElement>
              <IconButton
                aria-label="Toggle new password visibility"
                icon={showPassword.newPassword ? <RiEyeCloseLine /> : <MdOutlineRemoveRedEye />}
                onClick={() => toggleShowPassword("newPassword")}
                size="sm"
                variant="ghost"
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl>
          <FormLabel color={textColor} fontSize="sm" mt={4}>New Password Confirmation *</FormLabel>
          <InputGroup>
            <Input
              name="passwordConfirmation"
              onChange={handleInputChange}
              color={textColor}
              fontSize="sm"
              placeholder="New Password Confirmation"
              value={formData.passwordConfirmation}
              type={showPassword.passwordConfirmation ? "text" : "password"}
            />
            <InputRightElement>
              <IconButton
                aria-label="Toggle new password confirmation visibility"
                icon={showPassword.passwordConfirmation ? <RiEyeCloseLine /> : <MdOutlineRemoveRedEye />}
                onClick={() => toggleShowPassword("passwordConfirmation")}
                size="sm"
                variant="ghost"
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Flex justifyContent="flex-end" w="full" mt={4}>
          <Button
            size="sm"
            bg="#5158FF"
            onClick={handleSubmit}
            isLoading={loading}
            w="30%"
            py={5}
            mt={3}
          >
            Change Password
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ChangePassword;
