import { Avatar, Box, Flex, Icon, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { FaGear, FaUsersGear } from "react-icons/fa6";
import { MdChevronLeft, MdHome } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Overview from "../../views/profile/Overview";
import Users from "../../views/profile/Users";
import { RiLayoutMasonryFill } from "react-icons/ri";
import { capitalizeWords } from "utils/dataFormatter";
import { useAuth } from "AuthContext";
import Settings from "views/profile/Settings";

const menu = [
  {
    icon: MdHome,
    text: 'Overview',
    component: <Overview />
  },
  {
    icon: FaUsersGear,
    text: 'Users & permissions',
    component: <Users />
  },
  {
    icon: RiLayoutMasonryFill,
    text: 'Widgets',
    component: null
  },
  {
    icon: FaGear,
    text: 'Settings',
    component: <Settings />
  }
];

const Profile = () => {
  const [selectedMenuText, setSelectedMenuText] = useState(menu[0].text);
  const [selectedComponent, setSelectedComponent] = useState(menu[0].component);
  const { firstName, lastName, company } = useAuth();
  const navigate = useNavigate();
  const bg = useColorModeValue('background.100', 'background.900');
  const sidebarBg = useColorModeValue('white', 'navy.800');

  const formattedCompanyName = company
    ? capitalizeWords(company)
    : '';

  const handleMenuClick = (item) => {
    setSelectedMenuText(item.text);
    setSelectedComponent(item.component);
  };

  return (
    <Flex bg={bg} h="100vh" w="100vw" px="20px" py="35px" direction="column">
      <Flex gap="20px" flex="1" align="flex-start">
        {/* Left side menu */}
        <Flex direction="column" w="280px" alignItems="center">
          <Text
            cursor="pointer"
            fontSize="sm"
            textDecoration="underline"
            _hover={{ color: '#5158FF' }}
            onClick={() => navigate('/dashboard/overview')}
            height="60px"
            display="flex"
            mb="13px"
          >
            <Flex alignItems="flex-end">
              <Icon as={MdChevronLeft} w="24px" h="24px" mr="4px" />
              Back to Dashboard
            </Flex>
          </Text>

          <Flex 
            bg={sidebarBg} 
            borderRadius="20px" 
            boxShadow="md" 
            py={10}
            alignSelf="start"
            h="auto"
            direction="column"
            w="100%"
          >
            <Flex mb={2} direction="row" gap={4} px={7}>
              <Avatar
                h="48px"
                w="48px"
                color="white"
                name={capitalizeWords(`${firstName} ${lastName}`)}
                bg="#5158FF"
                size="sm"
                alignItems="center"
                justifyContent="center"
              />
              <Flex direction="column">
                <Text fontSize="md" fontWeight="bold">
                  {capitalizeWords(`${firstName} ${lastName}`)}
                </Text>
                {
                  company && <Text fontSize="xs">
                    {capitalizeWords(company?.replaceAll('_', ' '))}
                  </Text>
                }
              </Flex>
            </Flex>
            <Box borderBottom="1px solid" borderColor="gray.600" width="100%" mt={4} mb={5} />
            <VStack align="start" spacing="1" w="full" px={7}>
              {menu.map((item, index) => (
                <Flex 
                  key={index} 
                  align="center" 
                  cursor="pointer" 
                  _hover={{ color: '#5158FF' }} 
                  onClick={() => handleMenuClick(item)}
                  height="50px"
                  display="flex"
                  alignItems="center"
                >
                  <Icon as={item.icon} w="20px" h="20px" mr="3" />
                  <Text fontSize="sm" fontWeight={selectedMenuText === item.text ? "bold" : "normal"}>
                    {item.text}
                  </Text>
                </Flex>
              ))}
            </VStack>
          </Flex>
        </Flex>

        {/* Right side */}
        <Flex direction="column" flex="3">
          <Flex direction="column" ml={5}>
            <Text fontSize="sm" display="flex" alignItems="center">
              Account / {selectedMenuText}
            </Text>
            <Text fontSize="3xl" fontWeight="bold" mb="7px" display="flex" alignItems="center">
              {selectedMenuText}
            </Text>
          </Flex>
          
          <Flex 
            maxHeight="85vh" 
            overflowY="auto"
            flex="1"
          >
            {selectedComponent}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Profile;
