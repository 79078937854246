// AuthContext.js
// React Imports
import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { refreshToken } from 'utils/AuthUtils';
import { signIn } from 'utils/AuthUtils';
import locationsList from 'variables/locationsList';
import { allAvailablePages } from 'utils/AuthUtils';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem('authenticated') === 'true' || localStorage.getItem('authenticated') === 'true');
  const [cognitoUser, setCognitoUser] = useState(null);
  const [userID, setUserID] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [sparkCredits, setSparkCredits] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [availablePages, setAvailablePages] = useState([]);
  const [company, setCompany] = useState(null);
  const [brandText, setBrandText] = useState('Overview');
  const [userType, setUserType] = useState('ViewerUser');
  const [userPoolId, setUserPoolId] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);
  const [aboutMe, setAboutMe] = useState(null);
  const [isMfaEnabled, setIsMfaEnabled] = useState(false);
  const [keepSignedInState, setKeepSignedInState] = useState(localStorage.getItem('keepSignIn') === 'true');
  const navigate = useNavigate();
  const reportTemplateRef = useRef(null);

  useEffect(() => {
    const authState = sessionStorage.getItem('authenticated') === 'true' || localStorage.getItem('authenticated') === 'true';

    if (authState) {
      setIsAuthenticated(true);
      setUserID(sessionStorage.getItem('userID') || localStorage.getItem('userID'));
      setCompany(sessionStorage.getItem('company') || localStorage.getItem('company'));
      setFirstName(sessionStorage.getItem('firstName') || localStorage.getItem('firstName'));
      setLastName(sessionStorage.getItem('lastName') || localStorage.getItem('lastName'));
      setEmail(sessionStorage.getItem('email') || localStorage.getItem('email'));
      setSparkCredits(parseInt(sessionStorage.getItem('sparkCredits'), 10) || parseInt(localStorage.getItem('sparkCredits'), 10));
      setStartDate(sessionStorage.getItem('startDate') || localStorage.getItem('startDate'));
      setAvailableLocations(JSON.parse(sessionStorage.getItem('availableLocations')) || JSON.parse(localStorage.getItem('availableLocations')));
      setAvailablePages(JSON.parse(sessionStorage.getItem('availablePages')) || JSON.parse(localStorage.getItem('availablePages')));
      setUserType(sessionStorage.getItem('userType') || localStorage.getItem('userType'));
      setUserPoolId(sessionStorage.getItem('userPoolId') || localStorage.getItem('userPoolId'));
      setClientId(sessionStorage.getItem('clientId') || localStorage.getItem('clientId'));
      setJobTitle(sessionStorage.getItem('jobTitle') || localStorage.getItem('jobTitle'));
      setAboutMe(sessionStorage.getItem('aboutMe') || localStorage.getItem('aboutMe'));
      setIsMfaEnabled(sessionStorage.getItem('isMfaEnabled') === "true" || localStorage.getItem('isMfaEnabled') === "true");
    } else {
      setIsAuthenticated(false);
      navigate('/auth/signin');
    }
  }, [navigate]);

  useEffect(() => {
    const storeSessionData = (newAccessToken, refreshToken, userAttributes, userPoolId, clientId) => {
      if (keepSignedInState) {
        localStorage.setItem('accessToken', newAccessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('authenticated', 'true');
        localStorage.setItem('userID', userAttributes.sub);
        localStorage.setItem('firstName', userAttributes.given_name);
        localStorage.setItem('lastName', userAttributes.family_name);
        localStorage.setItem('email', userAttributes.email);
        localStorage.setItem('company', userAttributes['custom:Client']);
        localStorage.setItem('sparkCredits', userAttributes['custom:Credits'] || 0);
        localStorage.setItem('startDate', userAttributes['custom:startDate']);
        localStorage.setItem('availableLocations', userAttributes['custom:Location'] === 'All' ? JSON.stringify(locationsList[userAttributes['custom:Client']]) : userAttributes['custom:Location']);
        localStorage.setItem('availablePages', userAttributes['custom:Pages'] === 'All' ? JSON.stringify(allAvailablePages) : userAttributes['custom:Pages']);
        localStorage.setItem('userType', userAttributes['custom:UserType']);
        localStorage.setItem('keepSignedIn', 'true');
        localStorage.setItem('userPoolId', userPoolId);
        localStorage.setItem('clientId', clientId);
        localStorage.setItem('jobTitle', userAttributes['custom:JobTitle'] || '');
        localStorage.setItem('aboutMe', userAttributes['custom:AboutMe'] || '');
      } else {
        sessionStorage.setItem('accessToken', newAccessToken);
        sessionStorage.setItem('refreshToken', refreshToken);
        sessionStorage.setItem('authenticated', 'true');
        sessionStorage.setItem('userID', userAttributes.sub);
        sessionStorage.setItem('firstName', userAttributes.given_name);
        sessionStorage.setItem('lastName', userAttributes.family_name);
        sessionStorage.setItem('email', userAttributes.email);
        sessionStorage.setItem('company', userAttributes['custom:Client']);
        sessionStorage.setItem('sparkCredits', userAttributes['custom:Credits'] || 0);
        sessionStorage.setItem('startDate', userAttributes['custom:startDate']);
        sessionStorage.setItem('availableLocations', userAttributes['custom:Location'] === 'All' ? JSON.stringify(locationsList[userAttributes['custom:Client']]) : userAttributes['custom:Location']);
        sessionStorage.setItem('availablePages', userAttributes['custom:Pages'] === 'All' ? JSON.stringify(allAvailablePages) : userAttributes['custom:Pages']);
        sessionStorage.setItem('userType', userAttributes['custom:UserType']);
        sessionStorage.setItem('keepSignedIn', 'true');
        sessionStorage.setItem('userPoolId', userPoolId);
        sessionStorage.setItem('clientId', clientId);
        sessionStorage.setItem('jobTitle', userAttributes['custom:JobTitle'] || '');
        sessionStorage.setItem('aboutMe', userAttributes['custom:AboutMe'] || '');
      }
    };
  
    const refreshTokenAndStore = () => {
      return refreshToken()
        .then(({ accessToken: newAccessToken, refreshToken, user, userAttributes, userPoolId, clientId }) => {
          console.log('Token refreshed:', newAccessToken);
          setCognitoUser(user);
          storeSessionData(newAccessToken, refreshToken, userAttributes, userPoolId, clientId);
        })
        .catch(err => {
          console.error('Token refresh failed:', err);
          logout();
        });
    };
  
    refreshTokenAndStore();
  
    const interval = setInterval(() => {
      const accessToken = sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');
      if (accessToken) {
        console.log('Checking access token expiration');
        if (isTokenExpired(accessToken)) {
          refreshTokenAndStore();
        }
      }
    }, 60000); // Check every minute
  
    return () => clearInterval(interval);
  }, []);

  const login = async (email, password, keepSignedIn, setIsMfaFormOpen, setOnMfaSubmit, setIsNewPasswordFormOpen, setOnNewPasswordSubmit) => {
    try {
      const { accessToken, refreshToken, userAttributes, user, userPoolId: defaultUserPoolId, clientId: defaultClientId } = await signIn(email, password, setIsMfaFormOpen, setOnMfaSubmit, setIsNewPasswordFormOpen, setOnNewPasswordSubmit, setField, setIsMfaEnabled);
      if (keepSignedIn) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('authenticated', 'true');
        localStorage.setItem('userID', userAttributes.sub);
        localStorage.setItem('firstName', userAttributes.given_name);
        localStorage.setItem('lastName', userAttributes.family_name);
        localStorage.setItem('email', userAttributes.email);
        localStorage.setItem('company', userAttributes['custom:Client']);
        localStorage.setItem('sparkCredits', userAttributes['custom:Credits'] || 0);
        localStorage.setItem('startDate', userAttributes['custom:startDate']);
        localStorage.setItem('availableLocations', userAttributes['custom:Location'] === 'All' ? JSON.stringify(locationsList[userAttributes['custom:Client']]) : userAttributes['custom:Location']);
        localStorage.setItem('availablePages', userAttributes['custom:Pages'] === 'All' ? JSON.stringify(allAvailablePages) : userAttributes['custom:Pages']);
        localStorage.setItem('userType', userAttributes['custom:UserType']);
        localStorage.setItem('keepSignedIn', 'true');
        localStorage.setItem('userPoolId', defaultUserPoolId);
        localStorage.setItem('clientId', defaultClientId);
        localStorage.setItem('jobTitle', userAttributes['custom:JobTitle'] || '');
        localStorage.setItem('aboutMe', userAttributes['custom:AboutMe'] || '');
      }
      else {
        sessionStorage.setItem('accessToken', accessToken);
        sessionStorage.setItem('refreshToken', refreshToken);
        sessionStorage.setItem('authenticated', 'true');
        sessionStorage.setItem('userID', userAttributes.sub);
        sessionStorage.setItem('firstName', userAttributes.given_name);
        sessionStorage.setItem('lastName', userAttributes.family_name);
        sessionStorage.setItem('email', userAttributes.email);
        sessionStorage.setItem('company', userAttributes['custom:Client']);
        sessionStorage.setItem('sparkCredits', userAttributes['custom:Credits'] || 0);
        sessionStorage.setItem('startDate', userAttributes['custom:startDate']);
        sessionStorage.setItem('availableLocations', userAttributes['custom:Location'] === 'All' ? JSON.stringify(locationsList[userAttributes['custom:Client']]) : userAttributes['custom:Location']);
        sessionStorage.setItem('availablePages', userAttributes['custom:Pages'] === 'All' ? JSON.stringify(allAvailablePages) : userAttributes['custom:Pages']);
        sessionStorage.setItem('userType', userAttributes['custom:UserType']);
        sessionStorage.setItem('keepSignedIn', 'true');
        sessionStorage.setItem('userPoolId', defaultUserPoolId);
        sessionStorage.setItem('clientId', defaultClientId);
        sessionStorage.setItem('jobTitle', userAttributes['custom:JobTitle'] || '');
        sessionStorage.setItem('aboutMe', userAttributes['custom:AboutMe'] || '');
      }
      document.cookie = `refreshToken=${refreshToken}; HttpOnly; Secure; SameSite=Strict`;
      setIsAuthenticated(true);
      setCognitoUser(user);
      setUserID(userAttributes.sub);
      setFirstName(userAttributes.given_name);
      setLastName(userAttributes.family_name);
      setEmail(userAttributes.email);
      setSparkCredits(parseInt(userAttributes['custom:Credits'], 10));
      setStartDate(userAttributes['custom:startDate']);
      setCompany(userAttributes['custom:Client']);
      setKeepSignedInState(keepSignedIn);
      setAvailableLocations(userAttributes['custom:Location'] === "All" ? locationsList[userAttributes['custom:Client']] : JSON.parse(userAttributes['custom:Location']));
      setAvailablePages(userAttributes['custom:Pages'] === "All" ? "All" : JSON.parse(userAttributes['custom:Pages']));
      setUserType(userAttributes['custom:UserType']);
      setUserPoolId(defaultUserPoolId);
      setClientId(defaultClientId);
      setJobTitle(userAttributes['custom:JobTitle']);
      setAboutMe(userAttributes['custom:AboutMe']);
      return { success: true }; // Indicate successful login
    } catch (error) {
      console.error('Login error: ', error);
      return { success: false, error }; // Indicate failed login
    }
  };

  const logout = () => {
    console.log('logged out')
    sessionStorage.clear();
    localStorage.clear();
    cognitoUser?.signOut();
    setCognitoUser(null);
    setIsAuthenticated(false);
    setUserID(null);
    setFirstName(null);
    setLastName(null);
    setEmail(null);
    setSparkCredits(null);
    setAvailableLocations([]);
    setAvailablePages([]);
    setStartDate(null);
    setCompany(null);
    setKeepSignedInState(false);
    setUserPoolId(null);
    setClientId(null);
    setJobTitle(null);
    setAboutMe(null);
    setIsMfaEnabled(false);
    navigate('/auth/signin');
  };

  const isTokenExpired = (token) => {
    if (!token) {
      return true;
    }
    
    const decoded = jwtDecode(token);
    const expirationTime = decoded.exp * 1000; // Convert to milliseconds
    const currentTime = Date.now();
  
    // Check if token will expire in the next minute (60000 ms)
    return expirationTime - currentTime < 60000;
  };

  const setField = (field, value) => {
    if (keepSignedInState) {
      return localStorage.setItem(field, value);
    }
    sessionStorage.setItem(field, value);
  };

  const changePassword = async (formData) => {
    await new Promise((resolve, reject) => {
      cognitoUser.changePassword(formData.oldPassword, formData.newPassword, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  };

  const selfDelete = async () => {
    await new Promise((resolve, reject) => {
      cognitoUser.deleteUser((err, result) => {
        if (err) {
          reject(err);
        } else {
          logout();
          resolve(result);
        }
      });
    });
  };

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      userID,
      company,
      firstName,
      lastName,
      email,
      sparkCredits,
      startDate,
      login,
      logout,
      reportTemplateRef,
      brandText,
      setBrandText,
      availableLocations,
      availablePages,
      userType,
      userPoolId,
      clientId,
      setField,
      setFirstName,
      setLastName,
      jobTitle,
      aboutMe,
      setJobTitle,
      setAboutMe,
      changePassword,
      selfDelete,
      isMfaEnabled,
      setIsMfaEnabled
    }}>
     {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);