import { Button, Checkbox, Flex, FormControl, FormLabel, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { FaUserCog } from "react-icons/fa";
import { MdBusiness, MdCalendarMonth, MdEmail, MdInfo, MdOutlinePerson, MdStarRate } from "react-icons/md";
import { SiLightning } from "react-icons/si";
import { allAvailablePages } from "utils/AuthUtils";
import locationsList from "variables/locationsList";

const UserDetails = ({ selectedUser, setSelectedUser }) => {
  const { firstName, lastName, email, company, credits, userType, userPages, userLocations, startDate, status } = selectedUser;
  function capitalizeFirstLetter(companyName) {
    return companyName
      .replace(/_/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  const formattedCompanyName = company
    ? capitalizeFirstLetter(company)
    : '';
  const allAvailableLocations = locationsList[company?.toLowerCase().replaceAll(' ', '_')];
  const pagesPermissions = Array.isArray(userPages)
    ? JSON.parse(userPages)
    : userPages?.includes('All')
    ? allAvailablePages
    : userPages?.split(',');

  const locationPermissions = Array.isArray(userLocations)
    ? userLocations
    : userLocations?.includes('All')
    ? allAvailableLocations
    : userLocations?.split(',');

  const handlePagesPermissionChange = (pageTitle, childTitle = null) => {
    setSelectedUser((prev) => {
      const updatedPages = allAvailablePages.map(page => {
        if (page.title === pageTitle) {
          if (childTitle && page.children) {
            page.children = page.children.map(child => {
              if (child.title === childTitle) child.value = !child.value;
              return child;
            });
          } else {
            page.value = !page.value;
          }
        }
        return page;
      });
      return { ...prev, userPages: updatedPages };
    });
  };

  const handleLocationsPermissionChange = (locationTitle) => {
    setSelectedUser((prev) => {
      const currentLocations = Array.isArray(prev.userLocations) ? prev.userLocations : prev.userLocations.includes('All') ? allAvailableLocations : [];
      const updatedLocations = currentLocations.includes(locationTitle)
        ? currentLocations.filter((loc) => loc !== locationTitle)
        : [...currentLocations, locationTitle];
  
      return { ...prev, userLocations: updatedLocations };
    });
  };

  return (
    <Flex
      direction="column"
      flex="2"
      p={5}
      pt={3}
      borderRadius="md"
      overflowY="auto"
      maxHeight="100vh"
    >
      <VStack align="start" spacing={3}>
        <Text fontSize="xl" fontWeight="bold">{firstName} {lastName}</Text>
        <Flex align="center">
          <Icon as={MdEmail} w={5} h={5} color="gray.600" mr={2} />
          <Text fontSize="sm">{email}</Text>
        </Flex>
        <Flex align="center">
          <Icon as={MdBusiness} w={5} h={5} color="gray.600" mr={2} />
          <Text fontSize="sm" fontWeight="medium">{formattedCompanyName}</Text>
        </Flex>
        <Flex align="center">
          <Icon as={userType === 'MasterUser' ? MdStarRate : userType === 'AdminUser' ? FaUserCog : MdOutlinePerson} color="gray.600" w={5} h={5} mr={2} />
          <Text fontSize="sm" fontWeight="medium">{userType}</Text>
        </Flex>
        <Flex align="center">
          <Icon as={MdCalendarMonth} color="gray.600" w={5} h={5} mr={2} />
          <Text fontSize="sm" fontWeight="medium">Start date: {new Date(startDate).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}</Text>
        </Flex>
        <Flex align="center">
          <Icon as={MdInfo} color="gray.600" w={5} h={5} mr={2} />
          <Text fontSize="sm" fontWeight="medium">Status: {status}</Text>
        </Flex>
        <Flex align="center">
          <Icon as={SiLightning} color="yellow.500" w={5} h={5} mr={2} />
          <Text fontSize="sm" fontWeight="medium">Credits: {credits || 0}</Text>
        </Flex>
      </VStack>

      <Flex 
        mt={6} 
        pt={6} 
        borderTop="1px" 
        borderColor="gray.600" 
        direction="column" 
        height="100%"
        justifyContent="space-between"
      >
        <HStack align="start" spacing={4} w="100%">
          <FormControl mb={4} flex="1">
            <FormLabel fontSize="sm">Pages Permissions</FormLabel>
            <VStack align="start" spacing={2}>
              {pagesPermissions.map((page) => (
                <VStack align="start" key={page.title} spacing={2} w="100%">
                  <Checkbox
                    isChecked={page.value}
                    onChange={() => handlePagesPermissionChange(page.title)}
                    sx={{
                      "& .chakra-checkbox__control": {
                        borderColor: "gray.400",
                        _checked: {
                          bg: "#00E5D1",
                          borderColor: "#00E5D1",
                          color: "black",
                        },
                      },
                      "&:hover .chakra-checkbox__control": {
                        borderColor: "#00E5D1",
                      },
                    }}
                  >
                    <Text fontSize="sm">{page.title}</Text>
                  </Checkbox>

                  {page.children && (
                    <VStack pl={4} align="start" spacing={2} w="100%">
                      {page.children.map((child) => (
                        <Checkbox
                          key={child.title}
                          isChecked={child.value}
                          onChange={() => handlePagesPermissionChange(page.title, child.title)}
                          sx={{
                            "& .chakra-checkbox__control": {
                              borderColor: "gray.400",
                              _checked: {
                                bg: "#00E5D1",
                                borderColor: "#00E5D1",
                                color: "black",
                              },
                            },
                            "&:hover .chakra-checkbox__control": {
                              borderColor: "#00E5D1",
                            },
                          }}
                        >
                          <Text fontSize="sm">{child.title}</Text>
                        </Checkbox>
                      ))}
                    </VStack>
                  )}
                </VStack>
              ))}
            </VStack>
          </FormControl>

          <FormControl mb={4} flex="1">
            <FormLabel fontSize="sm">Location Permissions</FormLabel>
            <VStack align="start" spacing={2}>
              {allAvailableLocations.map((location) => (
                <Checkbox
                  key={location}
                  isChecked={locationPermissions.includes(location)}
                  onChange={() => handleLocationsPermissionChange(location)}
                  sx={{
                    "& .chakra-checkbox__control": {
                      borderColor: "gray.400",
                      _checked: {
                        bg: "#00E5D1",
                        borderColor: "#00E5D1",
                        color: "black",
                      },
                    },
                    "&:hover .chakra-checkbox__control": {
                      borderColor: "#00E5D1",
                    },
                  }}
                >
                  <Text fontSize="sm">{location}</Text>
                </Checkbox>
              ))}
            </VStack>
          </FormControl>
        </HStack>
        <Button
          borderRadius="10px"
          mt={4}
          px={6}
          py={3}
          fontSize="xs"
          colorScheme="teal" 
          onClick={() => {}}
          alignSelf="flex-end"
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

export default UserDetails;
