// Content.js
// Chakra Imports
import {
  Avatar,
  Box,
  Flex,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Components
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
// AuthContext
import { useAuth } from 'AuthContext';

function SidebarContent(props) {
  const { routes, mini, hovered } = props;
  const textColor = useColorModeValue('navy.700', 'white');
  const { company } = useAuth();

  function capitalizeFirstLetter(companyName) {
    return companyName
      .replace(/_/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  const formattedCompanyName = company
    ? capitalizeFirstLetter(company)
    : '';

  return (
    <Flex direction="column" height="100%" pt="25px" borderRadius="30px">
      <Brand mini={mini} hovered={hovered} />
      <Stack direction="column" mb="auto" mt="8px">
        <Box
          ps={
            mini === false
              ? '20px'
              : mini === true && hovered === true
              ? '20px'
              : '16px'
          }
          pe={{ md: '16px', '2xl': '1px' }}
          ms={mini && hovered === false ? '-16px' : 'unset'}
          mt={3} // THIS CHANGE THE SPACE IN BETWEEN THE BRAND LOGO (TOP) AND THE ELEMENT ON THE SIDEBAR
        >
          <Links mini={mini} hovered={hovered} routes={routes}/>
        </Box>
      </Stack>
      <Flex 
        mt="50px" 
        mb="66px" 
        ml="-5px" 
        justifyContent="center" 
        alignItems="center" 
      >
        <Avatar
          h="48px"
          w="48px"
          color="white"
          name={formattedCompanyName}
          bg="#5158FF"
          size="sm"
          alignItems="center"
          justifyContent="center"
          me={
            mini === false
              ? '20px'
              : mini === true && hovered === true
              ? '20px'
              : '0px'
          }
        />
        <Text color={textColor} fontSize="md" fontWeight="700" ml="-10px" >
          {formattedCompanyName}
        </Text>
      </Flex>
    </Flex>
  );
}

export default SidebarContent;
