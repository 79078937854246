import { Avatar, Box, Flex, Grid, Text, useColorModeValue } from "@chakra-ui/react";
import { useAuth } from "AuthContext";
import { capitalizeWords } from "utils/dataFormatter";
import AccountSettings from "./components/settings/AccountSettings";
import ChangePassword from "./components/settings/ChangePassword";
import DeleteAccount from "./components/settings/DeleteAccount";
import TwoFactorAuthentication from "./components/settings/TwoFactorAuthentication";

const Settings = () => {
  const { firstName, lastName, email } = useAuth();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Grid templateColumns="repeat(1fr)" gap={6} w="50%">
      <Box
        bg={useColorModeValue('white', 'navy.800')}
        borderRadius="20px"
        boxShadow={shadow}
        px={8}
        py={6}
        display="flex"
        alignItems="center"
        w="full"
      >
        <Flex direction="row" gap={4} alignItems="center">
          <Avatar
            name={`${firstName} ${lastName}`}
            color="white"
            bg="#5158FF"
            boxSize="4.5rem"
          />
          <Flex direction="column">
            <Text fontSize="xl" fontWeight="bold" color={textColor}>
              {capitalizeWords(`${firstName} ${lastName}`)}
            </Text>
            <Text fontSize="sm" color={textColor}>
              {email}
            </Text>
          </Flex>
        </Flex>
      </Box>

      <AccountSettings />
      <TwoFactorAuthentication />
      <ChangePassword />
      <DeleteAccount />
    </Grid>
  );
};

export default Settings;
