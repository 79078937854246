import { Button, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { FaRegPaperPlane } from "react-icons/fa6";

const Confirmation = ({ formData, setIsAddingNewUser, fetchUsers }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const handleDoneClick = () => {
    setIsAddingNewUser(false);
    fetchUsers();
  };

  return (
    <Flex
      bg={useColorModeValue('white', 'navy.800')}
      borderRadius="20px"
      boxShadow={shadow}
      p={8}
      display="flex"
      w="45%"
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
        <Icon as={FaRegPaperPlane} boxSize={50} color="white" mt={3} mb={5} />
        <Text fontSize="xl" fontWeight="bold" color={textColor} mb={3}>Invitation sent!</Text>
        <Text fontSize="sm" color={textColor} mb={1}>Your invitation has been sent to</Text>
        <Text fontSize="sm" color={textColor}>{formData?.email}</Text>
        <Button
            size="sm"
            bg="#5158FF"
            w="25%"
            py={5}
            mt={10}
            onClick={handleDoneClick}
        >
            Done
        </Button>
    </Flex>
  );
};

export default Confirmation;
