import { Box, Flex, useToast } from "@chakra-ui/react";
import { useState } from "react";
import UserInfo from "./NewUserForm/UserInfo";
import ProgressBar from "./ProgressBar";
import Permissions from "./NewUserForm/Permissions";
import Confirmation from "./NewUserForm/Confirmation";
import { useAuth } from "AuthContext";
import AWS from 'aws-sdk';
import { getUsersAPI } from "utils/AuthUtils";
import { allAvailablePages } from "utils/AuthUtils";

const NewUserForm = ({ setIsAddingNewUser, loading, setLoading, fetchUsers }) => {
  AWS.config.update({ region: process.env.REACT_APP_AWS_REGION });
  const refreshToken = localStorage.getItem('refreshToken') || sessionStorage.getItem('refreshToken') || null;

  const { company, clientId, userPoolId } = useAuth();
  const steps = ["User Info", "Permissions", "Confirmation"];
  const [currentStep, setCurrentStep] = useState(0);
  const initialFormData = {
    firstName: "",
    lastName: "",
    email: "",
    userType: "ViewerUser",
    initialPassword: "P@ssw0rd",
    pages: allAvailablePages,
    credits: 50,
    client: company,
    location: [],
  };
  const [formData, setFormData] = useState(initialFormData);
  const toast = useToast();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const saveNewUser = async (newUser) => {
    try {
      setLoading(true);
      const cognito = new AWS.CognitoIdentityServiceProvider();
  
      const params = {
        AuthFlow: 'REFRESH_TOKEN_AUTH',
        ClientId: clientId,
        AuthParameters: {
          REFRESH_TOKEN: refreshToken,
        },
      };
  
      const data = await cognito.initiateAuth(params).promise();
      const idToken = data.AuthenticationResult.IdToken;
  
      const response = await fetch(
        `${getUsersAPI(company)}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({...newUser, userPoolId}),
        }
      );
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to save user: ${errorText}`);
      }
      toast({
        title: "User added.",
        description: `${newUser.email} has been added.`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      setCurrentStep(2);
    } catch (error) {
      console.error('Error adding new user: ', error.toString());
      const match = error.toString().match(/"error":"(.*?)"/);
      toast({
        title: match && match[1] ? match[1] : 'Something went wrong',
        description: 'Please try again',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setLoading(false);
    }
  };

  const component = [
    <UserInfo handleInputChange={handleInputChange} formData={formData} setIsAddingNewUser={setIsAddingNewUser} setCurrentStep={setCurrentStep} />,
    <Permissions handleInputChange={handleInputChange} formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} onSave={saveNewUser} loading={loading} />,
    <Confirmation handleInputChange={handleInputChange} formData={formData} setIsAddingNewUser={setIsAddingNewUser} fetchUsers={fetchUsers} />,
  ];

  return (
    <Flex width="full" justifyContent="center" position="relative">
      {/* Background image with gradient */}
      <Box
        position="absolute"
        top={5}
        left={5}
        right={5}
        height="350px"
        backgroundImage={`linear-gradient(to top left, rgb(58,27,227), rgb(133,137,247))`}
        backgroundSize="cover"
        backgroundPosition="center"
        borderRadius={15}
        zIndex={1}
      />
      <Flex
        direction="column"
        alignItems="center"
        width="full"
        zIndex={2}
        p={6}
        mt={130}
      >
        <Flex mb={6}>
          <ProgressBar steps={steps} currentStep={currentStep} />
        </Flex>

        {component[currentStep]}
      </Flex>
    </Flex>
  );
};

export default NewUserForm;
