import { Box, Button, Checkbox, Flex, FormControl, FormLabel, Grid, Input, Select, Text, useColorModeValue } from "@chakra-ui/react";
import { useAuth } from "AuthContext";
import locationsList from "variables/locationsList";

const Permissions = ({ onEdit, handleInputChange, formData, setFormData, setUserToEdit, loading }) => {
  const { company, userType, availableLocations, availablePages } = useAuth();
  const allAvailableLocations = locationsList[company];
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const credits = {
    "ViewerUser": 50,
    "AdminUser": 100,
    "MasterUser": 150,
    "": 0
  };

  const getUserTypeOptions = () => {
    const options = [
      <option value="ViewerUser">Viewer</option>,
      <option value="AdminUser">Creator</option>
    ];

    if (userType === "MasterUser") {
      options.push(<option value="MasterUser">Admin</option>);
    }

    return options;
  };

  const handlePagePermissionChange = (pageTitle, checked) => {
    const updatePermissions = (pages, checkedStatus) => {
      return pages.map((page) => {
        if (page.title === pageTitle) {
          if (page.children) {
            return {
              ...page,
              value: checkedStatus,
              children: page.children.map((subPage) => ({
                ...subPage,
                value: checkedStatus, // Set children status to the same as parent
              })),
            };
          }
          return { ...page, value: checkedStatus };
        }
  
        if (page.children) {
          // Recursively update the children
          const updatedChildren = updatePermissions(page.children, checkedStatus);
          const allChildrenChecked = updatedChildren.every(subPage => subPage.value === true);
          const allChildrenUnchecked = updatedChildren.every(subPage => subPage.value === false);
  
          // If all children are checked, set the parent as checked
          if (allChildrenChecked && !page.value) {
            return { ...page, value: true, children: updatedChildren };
          }
          
          // If all children are unchecked, set the parent as unchecked
          if (allChildrenUnchecked && page.value) {
            return { ...page, value: false, children: updatedChildren };
          }
  
          return { ...page, children: updatedChildren };
        }
        return page;
      });
    };
  
    setFormData((prev) => ({
      ...prev,
      pages: updatePermissions(prev.pages, checked),
    }));
  };

  const handleLocationPermissionChange = (location) => {
    setFormData((prev) => ({
      ...prev,
      location: prev.location.includes(location)
        ? prev.location.filter((loc) => loc !== location)
        : [...prev.location, location],
    }));
  };

  const confirmChanges = async () => {
    console.log('here', formData)
    await onEdit({
      ...formData,
      pages: JSON.stringify(formData.pages),
      location: JSON.stringify(formData.location)
    });
  };

  const areAllValuesFalse = (items) => {
    return items.every(item => {
      if (item.value) return false;
      
      if (item.children) {
        return areAllValuesFalse(item.children);
      }
      
      return true;
    });
  };

  return (
    <Flex
      bg={useColorModeValue('white', 'navy.800')}
      borderRadius="20px"
      boxShadow={shadow}
      p={8}
      display="flex"
      w="60%"
      direction="column"
    >
      <Text fontSize="xl" fontWeight="bold" color={textColor}>Permissions</Text>
      <Flex direction="column" w="full" mt={5}>
        <Grid templateColumns="repeat(2, 1fr)" mb={4} gap={3}>
          <FormControl>
            <FormLabel color={textColor} fontSize="sm">
              User Type *
            </FormLabel>
            <Select
              name="userType"
              onChange={handleInputChange}
              color={textColor}
              fontSize="sm"
              value={formData?.userType}
              placeholder="Select User Type"
            >
              {getUserTypeOptions()}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel color={textColor} fontSize="sm">Lightning Credits *</FormLabel>
            <Input
              name="credits"
              color={textColor}
              fontSize="sm"
              disabled
              value={credits[formData?.userType]}
            />
          </FormControl>
        </Grid>
        <Grid templateColumns="repeat(2, 1fr)" mb={4} gap={3}>
          <FormControl>
            <FormLabel color={textColor} fontSize="sm">
              Pages *
            </FormLabel>
            <Flex direction="column" gap={2} border="1px solid" borderColor="gray.700" borderRadius={5} px={5} py={3}>
              {availablePages
                .filter((page) => page.value) // Only include pages where value is true
                .map((page) => {
                  // Find the corresponding page in formData.pages
                  const formPage = formData.pages.find((fp) => fp.title === page.title);

                  return (
                    <Box key={page.title}>
                      <Checkbox
                        isChecked={formPage?.value || false} // Use formData.pages value
                        onChange={(e) => handlePagePermissionChange(page.title, e.target.checked)}
                        fontSize="sm"
                        sx={{
                          "& .chakra-checkbox__control": {
                            borderColor: "gray.400",
                            _checked: {
                              bg: "#5158FF",
                              borderColor: "#5158FF",
                              color: "white",
                            },
                            _disabled: {
                              bg: "gray",
                              borderColor: "gray",
                              cursor: "not-allowed",
                            },
                          },
                          "&:hover .chakra-checkbox__control": {
                            borderColor: "#5158FF",
                          },
                        }}
                      >
                        <Text fontSize="sm">{page.title}</Text>
                      </Checkbox>
                      {page.children && (
                        <Flex direction="column" pl={4}>
                          {page.children
                            .filter((subPage) => subPage.value) // Only include subpages where value is true
                            .map((subPage) => {
                              // Find the corresponding subpage in formPage.children
                              const formSubPage = formPage?.children?.find((fsp) => fsp.title === subPage.title);

                              return (
                                <Checkbox
                                  key={subPage.title}
                                  isChecked={formSubPage?.value || false} // Use formData.pages value
                                  onChange={(e) =>
                                    handlePagePermissionChange(subPage.title, e.target.checked)
                                  }
                                  fontSize="sm"
                                  sx={{
                                    "& .chakra-checkbox__control": {
                                      borderColor: "gray.400",
                                      _checked: {
                                        bg: "#5158FF",
                                        borderColor: "#5158FF",
                                        color: "white",
                                      },
                                      _disabled: {
                                        bg: "gray",
                                        borderColor: "gray",
                                        cursor: "not-allowed",
                                      },
                                    },
                                    "&:hover .chakra-checkbox__control": {
                                      borderColor: "#5158FF",
                                    },
                                  }}
                                >
                                  <Text fontSize="sm">{subPage.title}</Text>
                                </Checkbox>
                              );
                            })}
                        </Flex>
                      )}
                    </Box>
                  );
                })}
            </Flex>

          </FormControl>
          <FormControl>
            <FormLabel color={textColor} fontSize="sm">Locations *</FormLabel>
            <Flex direction="column" gap={2} border="1px solid" borderColor="gray.700" borderRadius={5} px={5} py={3}>
            {allAvailableLocations?.map((location) => (
              <Checkbox
                key={location}
                value={location}
                isChecked={formData.location?.includes(location)}
                onChange={() => handleLocationPermissionChange(location)}
                disabled={!availableLocations.includes(location)}
                sx={{
                  "& .chakra-checkbox__control": {
                    borderColor: "gray.400",
                    _checked: {
                      bg: "#5158FF",
                      borderColor: "#5158FF",
                      color: "white",
                    },
                    _disabled: {
                      bg: "gray",
                      borderColor: "gray",
                      cursor: "not-allowed",
                    },
                  },
                  "&:hover .chakra-checkbox__control": {
                    borderColor: "#5158FF",
                  },
                }}
              >
                <Text fontSize="sm">{location}</Text>
              </Checkbox>
            ))}
            </Flex>
          </FormControl>
        </Grid>
        <Flex justifyContent="center" gap={3}>
          <Button
            size="sm"
            bg="transparent"
            border="white 1px solid"
            w="25%"
            py={5}
            mt={3}
            onClick={() => setUserToEdit(null)}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            bg="#5158FF"
            w="25%"
            py={5}
            mt={3}
            onClick={confirmChanges}
            isLoading={loading}
            disabled={formData.location.length === 0 || areAllValuesFalse(formData.pages)}
          >
            Confirm changes
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Permissions;
