import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  IconButton,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import { useAuth } from "AuthContext";
import { useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";

const UserTable = ({ users, searchTerm, setUserToEdit, setUserToDelete, onDeleteUserModalOpen }) => {
  const { userType, email } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const sidebarBg = useColorModeValue('white', 'navy.800');

  const filteredUsers = users.filter(user => {
    const name = `${user.firstName} ${user.lastName};` 
    return name.toLowerCase().includes(searchTerm.toLowerCase())
  });

  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage + 1;
  const endIndex = Math.min(currentPage * rowsPerPage, filteredUsers.length);
  const totalUsers = filteredUsers.length;

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <Box bg={sidebarBg} borderRadius="20px" p={4}>
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Join Date</Th>
            <Th>User Type</Th>
            {userType !== 'ViewerUser' && <Th>User Actions</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {paginatedUsers.map((user) => (
            <Tr key={user.id} fontSize="sm">
                <Td>
                <Flex align="center">
                  <Avatar
                    size="md"
                    name={`${user.firstName} ${user.lastName}`}
                    src={user.avatarUrl}
                    mr={3}
                  />
                  {`${user.firstName} ${user.lastName}`}
                </Flex>
              </Td>
              <Td>{user.email}</Td>
              <Td>{new Date(user.startDate).toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })}</Td>
              <Td>{user.userType === 'ViewerUser' ? 'Viewer' : user.userType === 'MasterUser' ? 'Admin' : 'Creator'}</Td>
              {
                userType !== 'ViewerUser' &&
                <Td>
                  {(user.email !== email && (user.userType !== "MasterUser" || userType !== "AdminUser")) && (
                    <>
                      <IconButton
                        size="lg"
                        variant="ghost"
                        aria-label="Edit user"
                        icon={<MdEdit />}
                        onClick={() => {
                          setUserToEdit(user);
                        }}
                      />
                      <IconButton
                        size="lg"
                        variant="ghost"
                        aria-label="Delete user"
                        icon={<MdDelete />}
                        onClick={() => {
                          setUserToDelete(user);
                          onDeleteUserModalOpen();
                        }}
                      />
                    </>
                  )}
                </Td>
              }
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex
        justify="space-between"
        align="center"
        mt={4}
        w="100%"
        direction={{ base: "column", md: "row" }}
      >
        {/* Rows per page dropdown */}
        <Flex align="center" mb={{ base: 3, md: 0 }}>
          <Text mr={2} fontSize="xs">Show rows per page:</Text>
          <Select
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(Number(e.target.value))}
            size="sm"
            maxW="100px"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </Select>
        </Flex>

        {/* Pagination Controls */}
        <Flex align="center">
          <Text mr={4} fontSize="xs">
            {startIndex}-{endIndex} of {totalUsers}
          </Text>
          <Flex>
            <Button
              onClick={() => handlePageChange("prev")}
              isDisabled={currentPage === 1}
              size="sm"
              variant="ghost"
            >
              <ChevronLeftIcon w={5} h={5} />
            </Button>
            <Button
              onClick={() => handlePageChange("next")}
              isDisabled={currentPage === totalPages}
              size="sm"
              variant="ghost"
            >
              <ChevronRightIcon w={5} h={5} />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default UserTable;
