import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import { useState } from "react";
import { MdChevronLeft, MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

const NewPasswordForm = ({ onClose, onSubmit }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [show, setShow] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const textColorDetails = useColorModeValue('secondaryGray.600', 'navy.700');
  const textColorSecondary = "gray.400";

  const handleSubmit = async (e) => {
    e.preventDefault();
    await onSubmit(newPassword);
    setNewPassword('');
    setConfirmationPassword('');
  };

  const handleClick = () => setShow(!show);
  const handleClickConfirmation = () => setShowConfirmation(!showConfirmation);

  return (
    <>
      <Flex
        color={textColorSecondary}
        position="absolute"
        top={10}
        left={10}
        _hover={{ cursor: "pointer" }}
        onClick={onClose}
      >
        <Icon as={MdChevronLeft} w="20px" h="20px" mr="4px" />
        <Text fontSize="sm">Back to Sign In Page</Text>
      </Flex>
      <Box me="auto">
        <Heading color="black" fontSize="36px" mb="10px">
          New Password
        </Heading>
        <Text
          mb="36px"
          ms="4px"
          color={textColorSecondary}
          fontWeight="400"
          fontSize="md"
        >
          Enter your new password for next sign in
        </Text>
      </Box>
      <Flex
        zIndex="2"
        direction="column"
        w={{ base: "100%", md: "420px" }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: "auto", lg: "unset" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
      >
        <Flex align="center" mb="25px">
          <HSeparator />
        </Flex>
        <form onSubmit={handleSubmit}>
          <FormControl mb="25px">
            <>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color="black"
                isRequired={true}
                display="flex"
              >
                New Password
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  color={textColorDetails}
                  placeholder="Your new password"
                  mb="24px"
                  size="lg"
                  type={show ? 'text' : 'password'}
                  variant="auth"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
            </>
            <>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color="black"
                isRequired={true}
                display="flex"
              >
                New Password Confirmation
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  color={textColorDetails}
                  placeholder="Your new password confirmation"
                  mb="24px"
                  size="lg"
                  type={showConfirmation ? 'text' : 'password'}
                  variant="auth"
                  value={confirmationPassword}
                  onChange={(e) => setConfirmationPassword(e.target.value)}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={showConfirmation ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClickConfirmation}
                  />
                </InputRightElement>
              </InputGroup>
            </>
          </FormControl>
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            type="submit"
            borderRadius="2rem"
            className="login-button"
            disabled={newPassword !== confirmationPassword || !newPassword || !confirmationPassword}
          >
            Submit
          </Button>
        </form>
      </Flex>
    </>
  );
};

export default NewPasswordForm;
