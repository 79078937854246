import { Button, Flex, FormControl, FormLabel, Grid, Input, Text, Textarea, useColorModeValue, useToast } from "@chakra-ui/react";
import { useAuth } from "AuthContext";
import { useState } from "react";
import AWS from 'aws-sdk';
import { getUsersAPI } from "utils/AuthUtils";

const AccountSettings = () => {
  AWS.config.update({ region: process.env.REACT_APP_AWS_REGION });
  const refreshToken = localStorage.getItem('refreshToken') || sessionStorage.getItem('refreshToken') || null;

  const { firstName: defaultFirstName, lastName: defaultLastName, email: defaultEmail, jobTitle: defaultJobTitle, aboutMe: defaultAboutMe, clientId, userPoolId, userID: username, setField, setFirstName, setLastName, setJobTitle, setAboutMe, company } = useAuth();
  const initialFormData = {
    firstName: defaultFirstName,
    lastName: defaultLastName,
    email: defaultEmail,
    jobTitle: defaultJobTitle,
    aboutMe: defaultAboutMe
  };
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );
  const toast = useToast();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        setLoading(true);
        const cognito = new AWS.CognitoIdentityServiceProvider();

        const params = {
          AuthFlow: 'REFRESH_TOKEN_AUTH',
          ClientId: clientId,
          AuthParameters: {
            REFRESH_TOKEN: refreshToken,
          },
        };
        const data = await cognito.initiateAuth(params).promise();
        const idToken = data.AuthenticationResult.IdToken;

        const response = await fetch(
          `${getUsersAPI(company)}`,
          {
            method: 'PATCH',
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({ ...formData, userPoolId, username }),
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to save user: ${errorText}`);
        }

        toast({
          title: "Account updated.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
        
        setField('firstName', formData.firstName);
        setField('lastName', formData.lastName);
        setField('jobTitle', formData.jobTitle);
        setField('aboutMe', formData.aboutMe);
        setFirstName(formData.firstName);
        setLastName(formData.lastName);
        setJobTitle(formData.jobTitle);
        setAboutMe(formData.aboutMe);
      } catch (error) {
        console.error('Error editing new user: ', error.toString());
        const match = error.toString().match(/"error":"(.*?)"/);
        toast({
          title: match && match[1] ? match[1] : 'Something went wrong',
          description: 'Please try again',
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'top',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const validateForm = () => {
    const { firstName, lastName, email } = formData;
    if (!firstName || !lastName || !email) {
      toast({
        title: "Form Incomplete",
        description: "Please fill out all required fields.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top"
      });
      return false;
    }
    return true;
  };

  return (
    <Flex
      bg={useColorModeValue('white', 'navy.800')}
      borderRadius="20px"
      boxShadow={shadow}
      p={8}
      display="flex"
      alignItems="center"
      w="full"
      direction="column"
    >
      <Flex direction="column" w="full" mb={8}>
        <Text fontSize="xl" fontWeight="bold" color={textColor}>Account Settings</Text>
        <Text fontSize="sm" color={textColor}>Change your account information</Text>
      </Flex>

      <Flex direction="column" w="full">
        <Grid templateColumns="repeat(2, 1fr)" mb={4}>
          <FormControl>
            <FormLabel color={textColor} fontSize="sm">Email *</FormLabel>
            <Input
              name="email"
              onChange={handleInputChange}
              color={textColor}
              fontSize="sm"
              disabled
              value={formData.email}
            />
          </FormControl>
        </Grid>
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          <FormControl>
            <FormLabel color={textColor} fontSize="sm">First Name *</FormLabel>
            <Input
              name="firstName"
              onChange={handleInputChange}
              color={textColor}
              fontSize="sm"
              placeholder="First Name"
              value={formData.firstName}
            />
          </FormControl>
          <FormControl>
            <FormLabel color={textColor} fontSize="sm">Last Name *</FormLabel>
            <Input
              name="lastName"
              onChange={handleInputChange}
              color={textColor}
              fontSize="sm"
              placeholder="Last Name"
              value={formData.lastName}
            />
          </FormControl>
        </Grid>
        <FormControl>
          <FormLabel color={textColor} fontSize="sm" mt={4}>Job Title</FormLabel>
          <Input
            name="jobTitle"
            onChange={handleInputChange}
            color={textColor}
            fontSize="sm"
            placeholder="What do you do?"
            value={formData.jobTitle}
          />
        </FormControl>
        <FormControl>
          <FormLabel color={textColor} fontSize="sm" mt={4}>About me</FormLabel>
          <Textarea
            w="full"
            name="aboutMe"
            onChange={handleInputChange}
            color={textColor}
            fontSize="sm"
            placeholder="Tell something about yourself!"
            resize="vertical"
            value={formData.aboutMe}
          />
        </FormControl>
        <Flex justifyContent="flex-end" w="full" mt={4}>
          <Button
            size="sm"
            bg="#5158FF"
            onClick={handleSubmit}
            isLoading={loading}
            w="17%"
            py={5}
            mt={3}
            disabled={!formData.firstName || !formData.lastName || !formData.email}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AccountSettings;
