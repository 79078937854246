import {
  Button,
  Flex,
  Image,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { handleMfaSetup, handleMfaVerify, enableTotpForUser, disableTotpForUser } from "utils/AuthUtils";
import { useAuth } from "AuthContext";

const TwoFactorAuthentication = () => {
  const [isSetupInProgress, setIsSetupInProgress] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [secretCode, setSecretCode] = useState('');
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const { email, isMfaEnabled, setIsMfaEnabled, setField, userPoolId } = useAuth();
  const toast = useToast();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const secondaryTextColor = useColorModeValue("navy.700", "whiteAlpha.600");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const handleSetupButtonClick = async () => {
    try {
      setLoading(true);
      const { qrCodeUrl, associateSecretCode } = await handleMfaSetup(email);
      setQrCodeUrl(qrCodeUrl);
      setSecretCode(associateSecretCode);
      setIsSetupInProgress(true);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to enable MFA. Try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setLoading(false);
      setOtp("");
    }
  };

  const handleDisableButtonClick = async () => {
    try {
      setLoading(true);
      await disableTotpForUser(email, userPoolId);
      toast({
        title: "Two-Factor Authentication is disabled",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setIsMfaEnabled(false);
      setField("isMfaEnabled", "false");
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to disable MFA. Try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyClick = async () => {
    try {
      setLoading(true);
      await enableTotpForUser(email, userPoolId);
      await handleMfaVerify(email, otp);
      toast({
        title: "Success",
        description: "MFA has been successfully set up for your account.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setIsSetupInProgress(false);
      setIsMfaEnabled(true);
      setField("isMfaEnabled", "true");
    } catch (error) {
      toast({
        title: "Error",
        description: "Invalid verification code. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      bg={useColorModeValue("white", "navy.800")}
      borderRadius="20px"
      boxShadow={shadow}
      p={8}
      display="flex"
      alignItems="center"
      w="full"
      direction="column"
      justifyContent="space-between"
    >
      <Flex
        direction="row"
        w="full"
        justifyContent="space-between"
        alignItems="center"
        mb={6}
      >
        <Text fontSize="xl" fontWeight="bold" color={textColor}>
          Two-Factor Authentication
        </Text>
        <Text
          fontSize="xs"
          fontWeight="600"
          color={isMfaEnabled ? "rgb(81, 179, 122)": "rgb(221, 102, 87)"}
          py={1}
          px={3}
          bgColor={isMfaEnabled ? "rgba(81, 179, 122, 0.2)" : "rgba(221, 102, 87, 0.2)"}
          borderRadius={10}
        >
          {isMfaEnabled ? "Enabled" : "Disabled"}
        </Text>
      </Flex>
      {!isSetupInProgress && (
        <Flex
          direction="row"
          w="full"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontSize="sm" fontWeight="600" color={textColor}>
            Authenticator App
          </Text>
          <Flex direction="row" alignItems="center" gap={3}>
            <Text fontSize="sm" color={secondaryTextColor}>
              {isMfaEnabled ? "Configured" : "Not configured"}
            </Text>
            <Button size="sm" bg="#5158FF" onClick={isMfaEnabled ? handleDisableButtonClick : handleSetupButtonClick} p={5} isLoading={loading}>
              {isMfaEnabled ? "Disable" : "Set up"}
            </Button>
          </Flex>
        </Flex>
      )}
      {isSetupInProgress && (
        <Flex direction="column" alignItems="center" gap={4}>
          <Text fontSize="sm" color={textColor}>
            Scan the QR code with your Authenticator App.
          </Text>
          {qrCodeUrl && (
            <Image src={qrCodeUrl} alt="QR Code" boxSize="200px" mx="auto" />
          )}
          {secretCode && (
            <Text fontSize="xs" color={secondaryTextColor}>
              {secretCode}
            </Text>
          )}
          <Text fontSize="sm" color={textColor}>
            Enter the OTP from your Authenticator App
          </Text>
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="Enter OTP"
            style={{
              padding: "8px",
              fontSize: "14px",
              width: "100%",
              textAlign: "center",
              borderRadius: "10px"
            }}
          />
          <Flex gap={2} mt={2}>
            <Button size="sm" onClick={() => { setIsSetupInProgress(false); setOtp("") }} p={5} disabled={loading}>
              Cancel
            </Button>
            <Button size="sm" bg="#5158FF" onClick={handleVerifyClick} p={5} isLoading={loading}>
              Verify
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default TwoFactorAuthentication;
