import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Icon,
  Input,
  Text
} from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import { useState } from "react";
import { MdChevronLeft } from "react-icons/md";

const MfaCodeForm = ({ onClose, onSubmit }) => {
  const [mfaCode, setMfaCode] = useState(["", "", "", "", "", ""]);
  const textColorSecondary = "gray.400";

  const handleChange = (value, index) => {
    const updatedCode = [...mfaCode];
    updatedCode[index] = value.slice(-1);
    setMfaCode(updatedCode);

    if (value && index < 5) {
      const nextInput = document.getElementById(`mfa-${index + 1}`);
      nextInput?.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && !mfaCode[index]) {
      const prevInput = document.getElementById(`mfa-${index - 1}`);
      prevInput?.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await onSubmit(mfaCode.join(""));
    setMfaCode(["", "", "", "", "", ""]);
  };

  return (
    <>
      <Flex
        color={textColorSecondary}
        position="absolute"
        top={10}
        left={10}
        _hover={{ cursor: "pointer" }}
        onClick={onClose}
      >
        <Icon as={MdChevronLeft} w="20px" h="20px" mr="4px" />
        <Text fontSize="sm">Back to Sign In Page</Text>
      </Flex>
      <Box me="auto">
        <Heading color="black" fontSize="36px" mb="10px">
          2-Step Verification
        </Heading>
        <Text
          mb="36px"
          ms="4px"
          color={textColorSecondary}
          fontWeight="400"
          fontSize="md"
        >
          Enter your 6-digit verification code to sign in
        </Text>
      </Box>
      <Flex
        zIndex="2"
        direction="column"
        w={{ base: "100%", md: "420px" }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: "auto", lg: "unset" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
      >
        <Flex align="center" mb="25px">
          <HSeparator />
        </Flex>
        <form onSubmit={handleSubmit}>
          <FormControl mb="25px">
            <Flex justify="center" gap="10px">
              {mfaCode.map((code, index) => (
                <Input
                  key={index}
                  id={`mfa-${index}`}
                  type="number"
                  maxLength="1"
                  value={code}
                  onChange={(e) => handleChange(e.target.value, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  textAlign="center"
                  fontSize="lg"
                  fontWeight="bold"
                  w="50px"
                  h="50px"
                  borderColor="gray.300"
                  borderWidth="2px"
                  _focus={{ borderColor: "#5158FF" }}
                  _hover={{ borderColor: "gray.200" }}
                />
              ))}
            </Flex>
          </FormControl>
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            type="submit"
            borderRadius="2rem"
            className="login-button"
          >
            Submit
          </Button>
        </form>
      </Flex>
    </>
  );
};

export default MfaCodeForm;
